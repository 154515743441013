import Vue from 'vue'

import ElementUI from 'element-ui';
import '@assets/element/index.css';
import App from './App.vue'
import router from './router'
import less from 'less'
import store from './store'
import YDB from '@utils/youdingb.js'
import infiniteScroll from 'vue-infinite-scroll'
// import MetaInfo from 'vue-meta-info';

import '@assets/css/global.css'
import '@assets/iconfont/iconfont.css'
import '@assets/hwicon/iconfont.css'
import '@assets/less/reset.less'

Vue.use(ElementUI);
Vue.use(less)
Vue.use(infiniteScroll)
// Vue.use(MetaInfo)

Vue.config.productionTip = false;
Object.assign(Vue.prototype, YDB);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')