<template>
    <el-container id="app" class="container">
        <el-header v-if="!isCreate">
            <my-header></my-header>
        </el-header>
        <el-main>
            <div class="layout w1200">
                <router-view></router-view>
            </div>
            <div class="top-btn" @click="goTop()"><i class="el-icon-caret-top"></i></div>
        </el-main>
        <my-footer v-if="!isCreate"></my-footer>
        <Login v-if="s_getLoginModal"></Login>
        <RegM v-if="s_getRegModal"></RegM>
        <div class="wx-box" v-if="s_getWxBg" @click.stop="s_setWxBg()">
            <p><i class="iconfont icon-zhiyinjiantou"></i></p>
            <button class="btn">{{s_getWxBg==1 ? '请点击右上角，使用浏览器打开': '请点击右上角下载APP后操作'}}</button>
        </div>
    </el-container>
</template>
<script>
    import myHeader from "@components/common/header.vue";
    import myFooter from "@components/common/footer.vue";
    import Login from "@components/common/login.vue";
    import RegM from "@components/common/reg_quick.vue";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        name: "app",
        components: {
            myHeader,
            myFooter,
            Login,
            RegM
        },
        data() {
            // 这里存放数据
            return {
                isCreate: 0
            };
        },
        created() {
            this.$y_setWebToken();
            this.getAbout();
        },
        mounted() {
            this.getConfig();
            this.$y_setKey("lon", "113.320234");
            this.$y_setKey("lat", "23.022241");
            this.getUserInfo();
            this.getCate();
        },
        watch: {
            $route(to, from) {
                // 路径判断
                let info = {
                    'create': 1,
                    'team': 1,
                    'frozen': 1,
                    'frozendes': 1,
                    'dydes': 1,
                }
                let p = to.path.replace('/', '');
                if (info[p] || p.indexOf('h5') != -1) {
                    this.isCreate = 1;
                }
            },
        },
        // 监听属性 类似于data概念
        computed: {
            ...mapGetters(["s_getLoginModal", "s_getRegModal", "s_getWxBg"]),
        },
        // 方法集合
        methods: {
            ...mapMutations(["s_setUser", "s_setWxBg"]),
            async getCate() {
                // let { data } = await this.$y_ajax("api/nihao/category");
                let {
                    data
                } = await this.$y_ajax("api/dymanic/category");
                this.$y_setKey("cate", data);
            },
            async getConfig() {
                let {
                    data
                } = await this.$y_ajax("api/member/config");
                this.$y_setKey("config", data);
                if (data.oss_switch == 1 && data.Endpoint && data.Bucket) {
                    // let url = data.request_scheme + data.Bucket + '.' + data.Endpoint;
                    let url = "//" + data.Bucket + "." + data.Endpoint;
                    this.$y_setKey("imgurl", url);
                }
                if (data.oss_urlhead) {
                    this.$y_setKey('zmoss', data.oss_urlhead);
                } else {
                    this.$y_rmKey('zmoss')
                }
            },
            async getUserInfo() {
                if (this.$y_getKey("userid") && this.$y_getKey("token")) {
                    let result = await this.$y_getUser();
                    if (result) {
                        this.s_setUser(result);
                    }
                }
            },
            goTop() {
                document.querySelector("html").scrollTop = 0;
            },
            async getAbout() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/help/question", {
                    cid: 9,
                });
                if (code == 200) {
                    data.forEach((v) => {
                        v.content = "";
                    });
                    this.$y_setKey("about", data);
                    this.aList = data;
                }
            },
        },
    };
</script>
<style>
    ul,
    li {
        list-style: none;
    }

    .el-header {
        height: auto !important;
    }

    input,
    textarea {
        font-family: sans-serif;
    }

    input,
    textarea,
    button {
        outline: 0;
        border: 0;

    }

    .hwiconfont.icon-fanhui {
        padding: 10px;
        font-size: 20px;
        display: inline-block;
    }

    .container {
        height: 100%;
        min-width: 1200px;
    }

    .layout {
        min-height: 60vh;
    }

    .page-box {
        padding-bottom: 15px;
        text-align: right;
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .flex-c {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .flex-a {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .flex-w {
        display: flex;
        flex-wrap: wrap;
    }

    .flex-bt {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .flex-start {
        display: flex;
        justify-content: flex-start;
    }

    .flex-end {
        display: flex;
        justify-content: flex-end;
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    /* 省略 */
    .ellipsis-1 {
        display: -webkit-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .ellipsis-2 {
        display: -webkit-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .ellipsis-3 {
        display: -webkit-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    /* 头像 */
    .tx {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
    }

    /* 头部 城市弹窗 */
    .city-box.el-popover {
        background: transparent;
        border: 0;
        box-shadow: none;
        padding: 0;
        margin-top: 0;
    }

    .top-btn {
        right: 10px;
        bottom: 50px;
        position: fixed;
        background: #fff;
        box-shadow: 0 2px 2px #aaa;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
    }

    .top-btn .el-icon-caret-top {
        font-size: 30px;
        color: #fd5621;
    }

    @media (max-width: 600px) {
        .container {
            width: 100%;
            min-width: auto;
        }

        /* confirm弹窗 */
        .el-message-box {
            width: 80vw;
        }
    }

    .score {
        font-size: 30px;
        color: orange;
    }

    .score .s-score {
        font-size: 16px;
    }



    .boy-tag {
        font-weight: normal;
        background-color: #03a9f4;
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 3px center;
        padding: 0px 3px;
        border-radius: 15px;
        font-size: 12px;
        color: #fff;
        margin-left: 3px;
    }

    .boy-tag::before {
        font-family: "iconfont" !important;
        font-size: 12px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e623";
        margin-right: 2px;
    }

    .girl-tag {
        background-color: #ff4a74;
    }

    .girl-tag::before {
        content: "\e622";
    }


    .new-sc {
        width: 30px;
        height: 30px;
        display: inline-block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('./assets/sc.png');
    }

    .active .new-sc {
        background-image: url('./assets/scs.png');
    }

    .wx-box {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.05);
        text-align: right;
    }

    .wx-box .iconfont {
        font-size: 150px;
    }

    .wx-box .btn {
        padding: 15px 20px;
        border-radius: 7px;
        border: 0;
        outline: 0;
        background: #fff;
        color: #fd5621;
        box-shadow: 0 1px 5px #fd5621;
        margin-right: 10px;
    }

    .more-btn {
        text-align: center;
        color: #aaa;
        padding: 15px 0;
    }
</style>