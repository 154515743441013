import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const getKey = (key) => {
    let v = window.localStorage.getItem(key);
    if (!v) return v;
    if (v.indexOf('obj-') === 0) {
        v = v.slice(4);
        return JSON.parse(v);
    } else if (v.indexOf('str-') === 0) {
        return v.slice(4);
    } else {
        window.localStorage.removeItem(key);
        return '';
    }
}
const setKey = (key, v) => {
    if (v === undefined) {
        window.localStorage.removeItem(key);
    } else {
        if (typeof v == 'object') {
            v = JSON.stringify(v);
            v = 'obj-' + v;
        } else {
            v = 'str-' + v;
        }
        window.localStorage.setItem(key, v);
    }
}

export default new Vuex.Store({
    state: {
        userInfo: null, // 登录用户信息
        showLogin: false, // 登录弹窗显示与隐藏
        showReg: false, // 注册弹窗显示与隐藏
        city: '', // 城市选择
        showWxBg: false, // 微信跳转浏览器的指引弹窗
        count: 0, // 消息数量
    },
    // getter
    getters: {
        s_getWxBg(state) {
            return state.showWxBg
        },
        s_getUser(state) {
            return state.userInfo || getKey('userInfo')
        },
        s_getLoginModal(state) {
            return state.showLogin
        },
        s_getRegModal(state) {
            return state.showReg
        },
        s_getCity(state) {
            let info = getKey('cityInfo');
            return state.city || (info ? info.ciy : '')
        },
        s_getCount(state) {
            return state.count || getKey('msgCount')
        }
    },
    // 内置都为同步操作在此处理即可 为setter
    mutations: {
        s_setWxBg(state, val = 0) {
            state.showWxBg = val;
        },
        s_setLoginModal(state, val = 0) {
            state.showLogin = val ? true : false;
        },
        s_setRegModal(state, val = 0) {
            state.showReg = val ? true : false;
        },
        s_setUser(state, val = null) {
            // val = user;
            // setKey('userid', '20093681');
            // setKey('token', token);
            setKey('userInfo', val)
            state.userInfo = val;
            if (!val) {
                setKey('userid', '');
                setKey('token', '');
            }
        },
        s_setCity(state, val = null) {
            setKey('cityInfo', val)
            if (val && val.city) {
                state.city = val.city;
            } else {
                state.city = ''
            }
        },
        s_setCount(state, val = 0) {
            state.count = val;
            setKey('msgCount', val)
        },
    },
    // 内置异步操作需在此处理 为setter
    actions: {}
})