<template>
    <div class="login">
        <el-dialog :visible.sync="s_getRegModal" :before-close="handleClose" class="login-dialog">
            <div class="login-wrapper">
                <!-- <div class="login-logo"><img src="../../assets/logo.png" alt="" /></div> -->
                <el-tabs v-model="nav" :stretch="true" type="card" @tab-click="submit = -1">
                    <el-tab-pane label="密码登录" name="0"></el-tab-pane>
                    <el-tab-pane label="短信登录/注册" name="1"></el-tab-pane>
                </el-tabs>
                <el-form>
                    <el-form-item>
                        <el-input v-model="phone" placeholder="请输入帐号"><i slot="prefix" class="el-icon-mobile"></i></el-input>
                        <p class="warn" v-if="!phone && submit != -1">请输入帐号</p>
                    </el-form-item>
                    <el-form-item v-show="nav == 0">
                        <el-input v-model="pwd" placeholder="请输入密码" show-password><i slot="prefix" class="el-icon-lock"></i></el-input>
                        <p class="warn" v-if="submit == 0 && !pwd">请输入密码</p>
                    </el-form-item>
                    <el-form-item v-show="nav == 1">
                        <div class="flex">
                            <el-input style="flex: 1" v-model="code" placeholder="请输入验证码"><i slot="prefix" class="el-icon-message"></i></el-input>
                            <sCode :phone="phone" @getCode="getCode"></sCode>
                        </div>
                        <p class="warn" v-if="submit == 1 && !code">请输入验证码</p>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">登录</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import sCode from "@components/common/code";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            sCode,
        },
        data() {
            // 这里存放数据
            return {
                nav: 0,
                codeTxt: "发送验证码",
                phone: "",
                pwd: "",
                code: "",
                submit: -1,
                city: {
                    show: 0,
                    val: "",
                },
            };
        },
        // 监听属性 类似于data概念
        computed: {
            ...mapGetters(["s_getRegModal"]),
        },
        mounted() {},
        // 方法集合
        methods: {
            ...mapMutations(["s_setUser", "s_setRegModal"]),
            getCode() {
                // 手机号不存在时会返回
                this.submit = 2;
            },
            handleClose() {
                this.s_setRegModal();
            },
            async submitForm() {
                this.submit = this.nav;
                let s = 1;
                if (!this.phone) s = 0;
                if (this.nav == 0 && !this.pwd) s = 0;
                if (this.nav == 1 && !this.code) s = 0;
                if (!s) return;
                let param = {
                    phone: this.phone,
                    device_id: this.$y_device(this.phone),
                };
                if (this.nav == 0) {
                    param.password = this.pwd;
                } else {
                    param.code = this.code;
                }
                this.$y_loading();
                const ret = await this.$y_ajax("api/login/index", param);
                if (ret && (ret.code == 200 || (this.nav == 1 && ret.code == 201))) {
                    let user = {};
                    if (ret.code == 200) {
                        // 已注册的账号 直接登录
                        user = ret.result;
                    } else {
                        let yq = this.$y_getKey("yq");
                        param.sex = '男';
                        param.birthday = '1998-01-01';
                        if (yq) {
                            param.send_code = yq;
                        }
                        // 短信且账号未注册 进行注册
                        user = await this.$y_ajax("api/reg/index", param);
                        if (user && user.code == 200) {
                            user.sex = '男';
                        } else {
                            this.$y_loadingClose();
                            this.$y_msg(user.msg, "error");
                        }
                    }
                    if (user && user.id) {
                        this.$y_msg("登录成功");
                        this.$y_setKey("userid", user.id);
                        this.$y_setKey("sex", user.sex);
                        this.$y_setKey("token", user.token);
                        const info = await this.$y_getUser();
                        if (info) {
                            this.s_setUser(info);
                        }
                        this.handleClose();
                        this.$y_loadingClose();
                    }
                } else {
                    this.$y_loadingClose();
                    this.$y_msg(ret.msg);
                }
            },
        },
    };
</script>
<style scoped lang="less">
    .login-logo {
        margin-bottom: 30px;
        text-align: center;

        img {
            width: 50%;
        }
    }

    .code {
        word-break: keep-all;
        margin-left: 15px;
        cursor: pointer;
    }

    .login-dialog {
        /deep/.el-dialog {
            width: 400px;
        }

        /deep/.el-dialog__body {
            padding: 30px 20px 0;
        }

        .dialog-footer {
            position: relative;
            z-index: 2;
            display: block;
            width: 100%;
            margin-bottom: 60px;

            .el-button {
                width: 100%;
            }
        }

        /deep/.el-dialog__footer {
            position: relative;

            &:after {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                content: "";
                width: 100%;
                height: 100%;
                opacity: 0.3;
                background: url(../../assets/login_bg2.jpg) center bottom no-repeat;
                background-size: contain;
            }
        }
    }

    .reg {
        text-align: center;
        margin-top: 30px;

        .reg-text {
            color: #fd5621;
        }
    }

    .warn {
        font-size: 12px;
        color: red;
        line-height: 1;
        margin-top: 5px;
    }
</style>