import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [{
    path: '/',
    redirect: '/index',
}, {
    path: '/index',
    name: 'index',
    component: () => import('@views/dynamic/index.vue')
}, {
    path: '/trap',
    name: 'trap',
    component: () => import('@views/article/trap.vue')
}, {
    path: '/create',
    name: 'create',
    component: () => import('@views/my/create.vue')
}, {
    path: '/frozen',
    name: 'frozen',
    component: () => import('@views/my/frozen.vue')
}, {
    path: '/frozendes',
    name: 'frozendes',
    component: () => import('@views/my/frozendes.vue')
}, {
    path: '/rank',
    name: 'rank',
    component: () => import('@views/rank/rank_des.vue')
}, {
    path: '/rankList',
    name: 'rankList',
    component: () => import('@views/rank/rank_frm.vue')
}, {
    path: '/rankRule',
    name: 'rankRule',
    component: () => import('@views/rank/rank_rule.vue')
}, {
    path: '/member',
    name: 'member',
    component: () => import('@views/my/team_member.vue')
}, {
    path: '/team',
    name: 'team',
    component: () => import('@views/my/team.vue'),
    children: [{
        path: '/',
        component: () => import('@views/my/team_income.vue')
    }]
}, {
    path: '/app_admin',
    name: 'app_admin',
    component: () => import('@views/my/dy_admin.vue'),
    children: [{
        path: '/',
        component: () => import('@views/my/mydt.vue')
    }]
}, {
    path: '/test',
    name: 'test',
    component: () => import('@views/test.vue')
}, {
    path: '/search',
    name: 'search',
    // component: () => import('@views/article/search.vue')
    component: () => import('@views/dynamic/search.vue')
}, {
    path: '/cate',
    name: 'cate',
    component: () => import('@views/article/cate.vue')
}, {
    path: '/list',
    name: 'list',
    component: () => import('@views/dynamic/list.vue')
}, {
    path: '/flag',
    name: 'flag',
    component: () => import('@views/flag/index.vue')
}, {
    path: '/act',
    name: 'act',
    component: () => import('@views/act/index.vue')
}, {
    path: '/dynamic',
    name: 'dynamic',
    component: () => import('@views/dynamic/index.vue')
}, {
    path: '/about',
    name: 'about',
    component: () => import('@views/about.vue')
}, {
    path: '/aboutMe',
    component: () => import('@views/aboutme.vue'),
}, {
    path: '/service',
    name: 'service',
    component: () => import('@views/service/index.vue')
}, {
    path: '/flagdes',
    name: 'flagdes',
    component: () => import('@views/flag/des.vue')
}, {
    // 经验改用动态渲染
    path: '/artdes',
    name: 'artdes',
    // component: () => import('@views/article/des.vue')
    component: () => import('@views/dynamic/des.vue')
}, {
    path: '/actdes',
    name: 'actdes',
    component: () => import('@views/act/des.vue')
}, {
    // app分享过来的 不显示头部
    path: '/dydes',
    name: 'dydes',
    component: () => import('@views/dynamic/des.vue')
}, {
    path: '/oridydes',
    name: 'oridydes',
    component: () => import('@views/dynamic/orides.vue')
}, {
    path: '/fwdes',
    name: 'fwdes',
    component: () => import('@views/service/des.vue')
    // }, {
    //     path: '/reg',
    //     name: 'reg',
    //     component: () => import('@views/login/reg.vue')
}, {
    path: '/my',
    component: () => import('@views/my/my.vue'),
    children: [{
        path: '/',
        component: () => import('@views/my/index.vue')
    }, {
        path: '/admin_ping',
        name: 'admin_ping',
        component: () => import('@views/my/admin_ping.vue')
    }, {
        path: '/admin_rz',
        name: 'admin_rz',
        component: () => import('@views/my/admin_rz.vue'),
        children: [{
            path: '/',
            component: () => import('@views/my/rz.vue')
        }]
    }, {
        path: '/admin_fans',
        name: 'admin_fans',
        component: () => import('@views/my/admin_fans.vue')
    }, {
        path: '/admin_mix',
        name: 'admin_mix',
        component: () => import('@views/my/admin_mix.vue')
    }, {
        path: '/mix_add',
        name: 'mix_add',
        component: () => import('@views/my/mix_add.vue')
    }, {
        path: '/admin_des',
        name: 'admin_des',
        // component: () => import('@views/my/mydt.vue')
        component: () => import('@views/my/dy_admin.vue'),
        children: [{
            path: '/',
            component: () => import('@views/my/mydt.vue')
        }]
    }, {
        path: '/ques',
        name: 'ques',
        component: () => import('@views/my/art_ques.vue')
    }, {
        path: '/add',
        name: 'add',
        component: () => import('@views/my/art_add.vue')
    }, {
        path: '/myfw',
        name: 'myfw',
        component: () => import('@views/my/fuwu.vue')
    }, {
        path: '/myact',
        name: 'myact',
        component: () => import('@views/my/act.vue')
    }, {
        path: '/chong',
        name: 'chong',
        component: () => import('@views/my/chong.vue')

    }]
}, {
    path: '/user',
    component: () => import('@views/user.vue'),
    children: [{
        path: '/',
        // component: () => import('@views/my/art.vue')
        component: () => import('@views/my/mydt.vue')
    }]
}, {
    path: '/h5',
    component: () => import('@views/h5/index.vue'),
    children: [{
        path: '/',
        component: () => import('@views/h5/dy.vue')
    }, {
        path: '/h5add',
        component: () => import('@views/h5/add.vue')
    }, {
        path: '/h5msg',
        component: () => import('@views/h5/msg.vue')
    }, {
        path: '/h5my',
        component: () => import('@views/h5/my.vue')
    }]
}, {
    path: '/h5des',
    name: 'h5des',
    component: () => import('@views/h5/des.vue')
}, {
    path: '/h5report',
    name: 'h5report',
    component: () => import('@views/h5/report.vue')
}, {
    path: '/h5auth',
    name: 'h5auth',
    component: () => import('@views/h5/auth.vue')
}, {
    path: '/h5sz',
    name: 'h5sz',
    component: () => import('@views/h5/sz.vue')
}, {
    path: '/h5search',
    name: 'h5search',
    component: () => import('@views/h5/search.vue')
}]
const router = new VueRouter({
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    routes
})
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
    if (document.querySelector('main')) {
        document.querySelector('main').scrollTop = 0
    }
})

export default router