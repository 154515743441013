<template>
    <footer class="footer">
        <div class="w1200">
            <div class="other">
                <img class="img" src="../../assets/logo.png" alt="">
                <a v-for="m in aList" :key="m.id" :href="m.url" target="_blank" class="link-btn"><span>{{m.title}}</span></a>
            </div>
            <div class="tip">
                <p>本站是一个互助社交和经验分享平台，可快捷了解相关经历情况。</p>
                <p>温馨提示：用户发布的文章、评论、信息等仅代表其个人观点，不代表本站同意其说法，请注意甄别并谨慎参阅，本站不承担由此引起的法律责任。如有疑问，可联系客服</p>
            </div>
            <div class="link-box">
                <router-link to="" class="link-btn"><span>Copyright©{{year}} 热浪版权所有</span></router-link>
                <router-link to="" class="link-btn"><span>开发者：海南光年之旅科技有限公司</span></router-link>
                <router-link to="" class="link-btn"><span @click="openG()">琼ICP备2023004623号</span></router-link>
            </div>
        </div>
    </footer>
</template>

<script>
    import QRCode from "qrcodejs2";
    export default {
        components: {},
        data() {
            // 这里存放数据
            return {
                aList: [],
                year: ''
            };
        },
        created() {},
        mounted() {
            this.year = new Date().getFullYear();
            this.getAbout();

            // let u = this.share_url;
            // if (this.$y_getKey("yq")) {
            //     u += "?yq=" + this.$y_getKey("yq");
            // }
            // new QRCode(this.$refs.qrCodeUrl, {
            //   text: u, // 需要转换为二维码的内容
            //   width: 150, // 二维码宽度
            //   height: 150, // 二维码高度
            //   colorDark: "#000000", // 二维码颜色
            //   colorLight: "#ffffff", // 二维码背景
            //   correctLevel: QRCode.CorrectLevel.H, //纠错等级容错级别，可设置为：QRCode.CorrectLevel.L、 QRCode.CorrectLevel.M 、QRCode.CorrectLevel.Q QRCode.CorrectLevel.H
            // });
        },
        // 监听属性 类似于data概念
        computed: {},
        // 方法集合
        methods: {
            openG() {
                window.open("//beian.miit.gov.cn/#/Integrated/recordQuery");
            },
            async getAbout() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/explore/index");
                if (code == 200) {
                    data.forEach((v) => {
                        v.content = "";
                    });
                    this.aList = data;
                }
            },
        },
    };
</script>
<style scoped lang="less">
    .footer {
        background: #f5f5f5;
        text-align: center;


        .other {
            padding: 30px 0;
            width: 100%;
            box-sizing: border-box;
            text-align: left;
            display: flex;
            align-items: flex-start;

            .img {
                width: 56px;
                height: 56px;
                border-radius: 20px;
                margin-right: 20px;
            }

            .link-btn {
                padding: 0;
                color: #555;
                font-size: 1.2rem;

                &:hover {
                    color: #fd5621;
                }

                &::after {
                    content: '|';
                    display: inline-block;
                    margin: 0 5px;
                    color: #aaa;
                }

            }

            .link-btn:last-child::after {
                display: none;
            }
        }

        .tip {
            margin-top: 20px;


            p {
                color: #bbb;
                margin-bottom: 7px;
            }
        }

        .link-btn {
            padding: 10px;
            display: inline-block;
        }

        .xieyi-ul {
            .xy-title {
                font-weight: normal;
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .footer {
            .other {
                display: block;

                .left-box {
                    width: 100%;
                    justify-content: center;

                    .list {
                        margin: 0 10px;
                    }
                }
            }

            .xieyi-ul {
                margin-top: 30px;
                text-align: center;
            }
        }
    }

    .app-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        padding: 10px;
        margin-bottom: 10px;

        .img {
            width: 35px;
            height: 35px;
            border-radius: 7px;
            position: absolute;
            background: #fff;
        }
    }
</style>